<template>
  <form>
    <div class="md-layout">
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedAttributeFormID"
        :dataArray="indexForms"
        :title="`${$formsLabel.toUpperCase()}`"
        type="plain-list"
        valueName="value"
        displayName="name"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedAttributeFormID"
        :dataArray="indexForms"
        :title="`${$formsLabel.toUpperCase()}`"
        type="plain-list"
        valueName="value"
        displayName="name"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <md-field md-inline>
        <label>Search For</label>
        <md-input id="searchFor" v-model="searchFor" name="searchFor" />
      </md-field>
      <md-field md-inline>
        <label>Replace With</label>
        <md-input id="replaceWith" v-model="replaceWith" name="replaceWith" />
      </md-field>
      <div class="md-layout-item" />
    </div>
    <md-button id="btnRun" class="md-dense md-raised md-primary mt-4" @click="runReplace()">
      Run
    </md-button>
    <div
      v-if="errorText"
      id="errorAlert"
      name="errorAlert"
      variant="danger"
    >
      {{ errorText }}
    </div>
    <div
      v-if="successText"
      id="successAlert"
      name="successAlert"
      variant="success"
    >
      {{ successText }}
    </div>
  </form>
</template>

<script>
export default {
  name: 'IndexFindReplace',
  data: function () {
    return {
      errorText: '',
      replaceWith: '',
      searchFor: '',
      selectedAttributeFormField: '',
      selectedAttributeFormID: 0,
      successText: '',
    }
  },
  computed: {
    indexFields() { return this.$store.state.common.indexFields },
    indexForms() { return this.$store.state.attributeForm?.forms },
  },
  watch: {
    selectedAttributeFormID: function () {
      if (typeof this.selectedAttributeFormID.value === 'undefined') {
        this.selectedAttributeFormID.value = 0
      }

      this.selectedAttributeFormField = ''
      this.$store.dispatch('common/getIndexFieldsForForm', this.selectedAttributeFormID.value)
    },
  },
  methods: {
    async runReplace() {
      this.errorText = ''
      this.successText = ''

      if (!this.selectedAttributeFormID.value) {
        this.errorText = `Please select ${$formsLabelWithPrefix}`
        return
      }

      if (!this.selectedAttributeFormField) {
        this.errorText = 'Please select a Field'
        return
      }

      if (this.searchFor === this.replaceWith) {
        this.errorText = 'Search For and Replace With text should be different'
        return
      }

      this.successText = 'Running Update...'
      const payload = {
        indexFormID: this.selectedAttributeFormID,
        indexFieldName: this.selectedAttributeFormField,
        oldValue: this.searchFor,
        newValue: this.replaceWith,
      }

      const response = await this.$store.dispatch('common/runFindReplaceIndexData', payload)
      if (!response || !response.data || !response.data.Value || response.data.Value === 'False') {
        this.successText = ''
        this.errorText = 'Error while updating data'
      } else {
        this.successText = 'Update Complete!'
      }
    },
  },
}
</script>

<style>

</style>
